import React, { useEffect } from "react"
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    navigate("/company/about")
  }, [])

  return <></>
}

export default IndexPage
